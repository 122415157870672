export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      title: 'Dashboard - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'dashboard',
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      requiresAuth: true,
      action: 'Read',
      resource: 'users',
    },
  },
  {
    path: '/user-page',
    name: 'user-page',
    component: () => import('@/components/general-table/GeneralTable.vue'),
    meta: {
      requiresAuth: true,
      action: 'Read',
      resource: 'users',
    },
  },
  {
    path: '/merchants',
    name: 'merchants',
    component: () => import('@/views/merchant/Merchants.vue'),
    meta: {
      title: 'Merchants - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'merchants',
      pageTitle: 'Shops',
      breadcrumb: [
        {
          text: 'Shops',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchants/view/:id',
    name: 'show-merchant',
    component: () => import('@/views/merchant/control-merchant/ViewMerchant.vue'),
    meta: {
      title: 'View Merchant - Zerocash',
      requiresAuth: true,
      action: 'read',
      resource: 'merchants',
    },
  },
  {
    path: '/merchants/view/:id/address/add',
    name: 'show-merchant-add-address',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      title: 'Merchants | Add Address - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'addresses',
    },
  },
  {
    path: '/merchants/view/:id/address/edit/:addressid',
    name: 'show-merchant-edit-address',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      title: 'Merchants | Edit Address - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'addresses',
    },
  },
  {
    path: '/user-contacts',
    name: 'friends',
    component: () => import('@/views/friends/Friends.vue'),
    meta: {
      title: 'User Contacts - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'friends',
      pageTitle: 'Friends',
      breadcrumb: [
        {
          text: 'Friends',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/views/offers/Offers.vue'),
    meta: {
      title: 'Offers - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('@/views/vouchers/Vouchers.vue'),
    meta: {
      title: 'Vouchers - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'offers',
      pageTitle: 'Vouchers',
      breadcrumb: [
        {
          text: 'Vouchers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/countries/Countries.vue'),
    meta: {
      title: 'Countries - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'countries',
    },
  },
  {
    path: '/branches',
    name: 'branches',
    component: () => import('@/views/branches/Branches.vue'),
    meta: {
      title: 'Branches - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'branches',
      pageTitle: 'Branches',
      breadcrumb: [
        {
          text: 'Branches',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/branches/view/:id',
    name: 'show-branche',
    component: () => import('@/views/branches/control-branch/ViewBranch.vue'),
    meta: {
      title: 'View Branch - Zerocash',
      requiresAuth: true,
      action: 'read',
      resource: 'branches',
    },
  },
  {
    path: '/branches/view/:id/address/add',
    name: 'show-branche-address',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      title: 'Branch | Add Address - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'addresses',
    },
  },
  {
    path: '/branches/view/:id/address/edit/:addressid',
    name: 'edit-branche-address',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      title: 'Branch | Edit Address - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'addresses',
    },
  },
  {
    path: '/payment-types',
    name: 'providers',
    component: () => import('@/views/providers/Providers.vue'),
    meta: {
      title: 'Payment Types  - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'providers',
      pageTitle: 'Providers',
      breadcrumb: [
        {
          text: 'Providers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant-types',
    name: 'merchant-types',
    component: () => import('@/views/merchant-types/MerchantTypes.vue'),
    meta: {
      title: 'Merchants Types - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'merchant_types',
      pageTitle: 'Merchant Types',
      breadcrumb: [
        {
          text: 'Merchant Types',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/add',
    name: 'add-offer',
    component: () => import('@/views/offers/control-offer/AddEditoffer.vue'),
    meta: {
      title: 'Add Offer - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/edit/:id',
    name: 'edit-offer',
    component: () => import('@/views/offers/control-offer/AddEditoffer.vue'),
    meta: {
      title: 'Edit Offer - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'offers',
      pageTitle: 'Offers',
      breadcrumb: [
        {
          text: 'Offers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations',
    name: 'donations',
    component: () => import('@/views/donations/Donations.vue'),
    meta: {
      title: 'Donations - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'donations',
      pageTitle: 'Donations',
      breadcrumb: [
        {
          text: 'Donations',
          to: 'donations',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/add',
    name: 'add-donation',
    component: () => import('@/views/donations/control-donations/AddEditDonations.vue'),
    meta: {
      title: 'Add Donations - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'donations',
      pageTitle: 'Donations',
      breadcrumb: [
        {
          text: 'Donations',
          to: '/donations',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/donations/edit/:id',
    name: 'edit-donation',
    component: () => import('@/views/donations/control-donations/AddEditDonations.vue'),
    meta: {
      title: 'Edit Donations - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'donations',
      pageTitle: 'Donations',
      breadcrumb: [
        {
          text: 'Donations',
          to: '/donations',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utilities',
    name: 'utilities',
    component: () => import('@/views/utilities/Utilities.vue'),
    meta: {
      title: 'Utilities - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'utility',
      pageTitle: 'Utilities',
      breadcrumb: [
        {
          text: 'Utilities',
          to: 'utilities',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utilities/add',
    name: 'add-utility',
    component: () => import('@/views/utilities/control-utilities/AddEditUtility.vue'),
    meta: {
      title: 'Add Utility - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'utility',
      pageTitle: 'Utilities',
      breadcrumb: [
        {
          text: 'Utilities',
          to: '/utilities',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utilities/edit/:id',
    name: 'edit-utility',
    component: () => import('@/views/utilities/control-utilities/AddEditUtility.vue'),
    meta: {
      title: 'Edit Utility - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'utility',
      pageTitle: 'Utilities',
      breadcrumb: [
        {
          text: 'Utilities',
          to: '/utilities',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCodes',
    name: 'promoCodes',
    component: () => import('@/views/promoCodes/PromoCode.vue'),
    meta: {
      title: 'Promo Codes - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'promo',
      pageTitle: 'Promo Codes',
      breadcrumb: [
        {
          text: 'Promo Codes',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCodes/add',
    name: 'add-promo-code',
    component: () => import('@/views/promoCodes/control-promoCode/AddEditpromoCode.vue'),
    meta: {
      title: 'Add Promo Code - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'promo',
      pageTitle: 'Promo Codes',
      breadcrumb: [
        {
          text: 'Promo Codes',
          to: '/promoCodes',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promoCodes/addBulk',
    name: 'add-bulk-promo-code',
    component: () => import('@/views/promoCodes/control-promoCode/AddBulkpromoCode.vue'),
    meta: {
      title: 'Add Bulk Promo Code - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'promo',
      pageTitle: 'Promo Codes',
      breadcrumb: [
        {
          text: 'Promo Codes',
          to: '/promoCodes',
        },
        {
          text: 'Add Bulk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vouchers/add',
    name: 'add-voucher',
    component: () => import('@/views/vouchers/control-voucher/AddEditvoucher.vue'),
    meta: {
      title: 'Add Offer - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'offers',
      pageTitle: 'Vouchers',
      breadcrumb: [
        {
          text: 'Vouchers',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vouchers/edit/:id',
    name: 'edit-voucher',
    component: () => import('@/views/vouchers/control-voucher/AddEditvoucher.vue'),
    meta: {
      title: 'Edit Offer - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'offers',
      pageTitle: 'Vouchers',
      breadcrumb: [
        {
          text: 'Vouchers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/Roles.vue'),
    meta: {
      title: 'Roles - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'roles',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/add',
    name: 'add-role',
    component: () => import('@/views/roles/control-roles/AddEditRoles.vue'),
    meta: {
      title: 'Add Role - Zerocash',
      requiresAuth: true,
      action: 'create',
      resource: 'roles',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/roles',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'edit-role',
    component: () => import('@/views/roles/control-roles/AddEditRoles.vue'),
    meta: {
      title: 'Edit Role - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'roles',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/roles',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      title: 'Notifications - Zerocash',
      requiresAuth: true,
      action: 'browse',
      resource: 'notifications',
    },
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('@/views/areas/Areas.vue'),
    meta: {
      title: 'Areas - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'areas',
      pageTitle: 'Areas',
      breadcrumb: [
        {
          text: 'Places',
        },
        {
          text: 'Areas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cities',
    name: 'cities',
    component: () => import('@/views/cities/Cities.vue'),
    meta: {
      title: 'Cities - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'cities',
      pageTitle: 'Cities',
      breadcrumb: [
        {
          text: 'Places',
        },
        {
          text: 'Cities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/states',
    name: 'states',
    component: () => import('@/views/states/States.vue'),
    meta: {
      title: 'States - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'states',
      pageTitle: 'States',
      breadcrumb: [
        {
          text: 'Places',
        },
        {
          text: 'States',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user-profile/UserProfile.vue'),
    meta: {
      title: 'User Settings - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'dashboard',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configs',
    name: 'configs',
    component: () => import('@/views/configs/Configs.vue'),
    meta: {
      title: 'Configrations - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Configs',
      breadcrumb: [
        {
          text: 'Configs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/moduel',
    name: 'moduel',
    component: () => import('@/views/modules/Module.vue'),
    meta: {
      title: 'Module Settings',
      requiresAuth: true,
      pageTitle: 'Module Settings',
      action: 'browes',
      resource: 'dashboard',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      title: 'Settings - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Configration Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
    children: [
      {
        path: ':group',
        name: 'general-settings',
        props: true,
        component: () => import('@/views/settings/GeneralConfigSetting.vue'),
        meta: {
          title: 'Settings',
          requiresAuth: true,
          pageTitle: 'Configration Settings',
          breadcrumb: [
            {
              text: 'Settings',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/merchant-requests',
    name: 'merchant-requests',
    component: () => import('@/views/merchant-requests/MerchantRequests.vue'),
    meta: {
      title: 'Merchant Requests',
      requiresAuth: true,
      pageTitle: 'Merchant Requests',
      action: 'browes',
      resource: 'configs',
    },
  },
  {
    path: '/cashouts',
    name: 'cashouts',
    component: () => import('@/views/cashouts/Cashouts.vue'),
    meta: {
      title: 'Cashouts',
      requiresAuth: true,
      pageTitle: 'Cashouts',
      action: 'browes',
      resource: 'cashouts',
    },
  },
  {
    path: '/cashout-types',
    name: 'cashout-types',
    component: () => import('@/views/cashout-types/CashoutTypes.vue'),
    meta: {
      title: 'Cashout Types',
      requiresAuth: true,
      pageTitle: 'Cashout Types',
      action: 'browes',
      resource: 'cashouts',
    },
  },
  {
    path: '/offer-categories',
    name: 'offer-categories',
    component: () => import('@/views/offer-categories/OfferCategories.vue'),
    meta: {
      title: 'Cashout Types',
      requiresAuth: true,
      pageTitle: 'Cashout Types',
      action: 'browes',
      resource: 'cashouts',
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/ticket/Tickets.vue'),
    meta: {
      title: 'Tickets',
      requiresAuth: true,
      pageTitle: 'Tickets',
      action: 'browes',
      resource: 'tickets',
    },
  },
  {
    path: '/ticket-categories',
    name: 'ticket-categories',
    component: () => import('@/views/ticket-categories/TicketCategories.vue'),
    meta: {
      title: 'Ticket Categories',
      requiresAuth: true,
      pageTitle: 'Ticket Categories',
      action: 'browes',
      resource: 'category',
    },
  },
  {
    path: '/fees-profiles',
    name: 'fees-profiles',
    component: () => import('@/views/fees-profiles/FeesProfiles.vue'),
    meta: {
      title: 'Fees Profiles',
      requiresAuth: true,
      pageTitle: 'Fees Profiles',
      action: 'browes',
      resource: 'fees',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/services/Services.vue'),
    meta: {
      title: 'Services',
      requiresAuth: true,
      pageTitle: 'Services',
      action: 'browes',
      resource: 'services',
    },
  },
  {
    path: '/config-services',
    name: 'config-services',
    component: () => import('@/views/config-services/ConfigService.vue'),
    meta: {
      title: 'Config Services',
      requiresAuth: true,
      pageTitle: 'Config Services',
      action: 'browes',
      resource: 'config',
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/faqs/FAQs.vue'),
    meta: {
      title: 'FAQs',
      requiresAuth: true,
      pageTitle: 'FAQs',
      action: 'browes',
      resource: 'faqs',
    },
  },
  {
    path: '/financial-reports',
    name: 'financial-reports',
    component: () => import('@/views/financial-reports/FinancialReports.vue'),
    meta: {
      title: 'Financial Reports',
      requiresAuth: true,
      pageTitle: 'Financial Reports',
      action: 'browes',
      resource: 'reports',
    },
  },
  {
    path: '/failed-transactions',
    name: 'failed-transactions',
    component: () => import('@/views/cashcall/cashcall-failed-transactions/CashCallFailedTransactions.vue'),
    meta: {
      title: 'Failed Transactions',
      requiresAuth: true,
      pageTitle: 'Failed Transactions',
      action: 'browes',
      resource: 'cashcall_transaction',
    },
  },
  {
    path: '/failed-transactions/view/:id',
    name: 'show-failed-transaction',
    component: () => import('@/views/cashcall/cashcall-failed-transactions/control-failed-transactions/ViewFailedTransaction.vue'),
    meta: {
      title: 'Failed Transaction',
      requiresAuth: true,
      action: 'read',
      resource: 'cashouts',
    },
  },
  {
    path: '/cashcall-wallet',
    name: 'cashcall-wallet',
    component: () => import('@/views/cashcall/cashcall-wallet/CashCallWallet.vue'),
    meta: {
      title: 'Cashcall Wallet',
      requiresAuth: true,
      pageTitle: 'Cashcall Wallet',
      action: 'browes',
      resource: 'cashcall_balance',
    },
  },
  {
    path: '/refund-waffarha',
    name: 'refund-waffarha',
    component: () => import('@/views/waffarha/refund-waffarha/RefundWaffarha.vue'),
    meta: {
      title: 'Refund Waffarha',
      requiresAuth: true,
      pageTitle: 'Refund Waffarha',
      action: 'browes',
      resource: 'waffarha_order',
    },
  },
  {
    path: '/order-details',
    name: 'order-details',
    component: () => import('@/views/waffarha/order-details/index.vue'),
    meta: {
      title: 'Order Details',
      requiresAuth: true,
      pageTitle: 'Order Details',
      action: 'browes',
      resource: 'waffarha_order',
    },
  },
  {
    path: '/damen-categories',
    name: 'damen-categories',
    component: () => import('@/views/damen-services/category-aggregators/CategoryAggregators.vue'),
    meta: {
      title: 'Damen Categories - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'cat_aggregator',
      pageTitle: 'Categories Aggregators',
      breadcrumb: [
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/damen-billers',
    name: 'damen-billers',
    component: () => import('@/views/damen-services/biller-aggregators/BillerAggregators.vue'),
    meta: {
      title: 'Damen Billers - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Billers Aggregators',
      breadcrumb: [
        {
          text: 'Billers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/damen-services',
    name: 'damen-services',
    component: () => import('@/views/damen-services/service-aggregators/ServiceAggregators.vue'),
    meta: {
      title: 'Damen Service - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'ser_aggregator',
      pageTitle: 'Service Aggregators',
      breadcrumb: [
        {
          text: 'Services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pending-transactions',
    name: 'pending-transactions',
    component: () => import('@/views/damen-services/pending-transactions/PendingTransactions.vue'),
    meta: {
      title: 'Pending Transactions - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'configs',
      pageTitle: 'Pending Transactions',
      breadcrumb: [
        {
          text: 'Pending Transactions',
          active: true,
        },
      ],
    },
  },
]
