export default [
  {
    path: '/waffarha-transactions',
    name: 'waffarha-transactions',
    component: () => import('@/views/waffarha-admin/WaffarhaTransactions.vue'),
    meta: {
      title: 'Waffarha Transactions',
      requiresAuth: true,
      pageTitle: 'Waffarha Transactions',
      action: 'browes',
      resource: 'waffarha',
    },
  },
  {
    path: '/view-waffarha-transaction/:id',
    name: 'view-waffarha-transaction',
    component: () => import('@/views/waffarha-admin/ViewWaffarhaTransaction.vue'),
    meta: {
      title: 'View Waffarha Transactions',
      requiresAuth: true,
      pageTitle: 'View Waffarha Transactions',
      action: 'read',
      resource: 'waffarha',
      breadcrumb: [
        {
          text: 'Waffarha Transactions',
          to: '/waffarha-transactions',

        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
