export default [
  {
    path: '/users/merchant',
    name: 'merchant-users',
    component: () => import('@/views/users/merchant/UsersList.vue'),
    meta: {
      title: 'Users - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'admin_users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    title: 'Users',
    path: '/users/merchant/add',
    name: 'add-user-merchant',
    component: () => import('@/views/users/merchant/control-users/AddUser.vue'),
    meta: {
      requiresAuth: true,
      title: 'Add User - Zerocash',
      action: 'create',
      resource: 'admin_users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/merchant/edit/:id',
    name: 'edit-user-merchant',
    component: () => import('@/views/users/merchant/control-users/AddUser.vue'),
    meta: {
      title: 'Edit User - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'admin_users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users/merchant',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/merchant/view/:id',
    name: 'show-user-merchant',
    component: () => import('@/views/users/merchant/control-users/ViewUser.vue'),
    meta: {
      requiresAuth: true,
      title: 'View User - Zerocash',
      action: 'read',
      resource: 'admin_users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users/merchant',
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/merchant/view/:id/address/add',
    name: 'add-address-merchant',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      requiresAuth: true,
      title: 'Uesr | Add Address - Zerocash',
      action: 'create',
      resource: 'addresses',
      pageTitle: 'User Address',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users/merchant',
        },
        {
          text: 'Details',
        },
        {
          text: 'Address',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/merchant/view/:id/address/edit/:addressid',
    name: 'edit-address-merchant',
    component: () => import('@/views/z-addresses/AddAddress.vue'),
    meta: {
      title: 'User | Edit Address - Zerocash',
      requiresAuth: true,
      action: 'update',
      resource: 'addresses',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users/add',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
