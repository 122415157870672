export default [

  {
    path: '/gaming-categories',
    name: 'gaming-categories',
    component: () => import('@/views/gaming/categories/Categories.vue'),
    meta: {
      title: 'Gaming Categories - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gaming Categories',
      breadcrumb: [
        {
          text: 'Gaming Categories',
          to: 'gaming-categories',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaming-categories/add',
    name: 'add-gaming-category',
    component: () => import('@/views/gaming/categories/control-categories/AddEditCategory.vue'),
    meta: {
      title: 'Add Category - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gaming Categories',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/gaming-categories',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaming-categories/edit/:id',
    name: 'edit-gaming-category',
    component: () => import('@/views/gaming/categories/control-categories/AddEditCategory.vue'),
    meta: {
      title: 'Edit Category - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gaming Categories',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/gaming-categories',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/gift-cards',
    name: 'gift-cards',
    component: () => import('@/views/gaming/giftCards/GiftCard.vue'),
    meta: {
      title: 'Gift Cards - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gift Cards',
      breadcrumb: [
        {
          text: 'Gift Cards',
          to: 'gift-cards',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gift-cards/add',
    name: 'add-gift-card',
    component: () => import('@/views/gaming/giftCards/control-gift-card/AddEditGiftCard.vue'),
    meta: {
      title: 'Add Gift Card - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gift Cards',
      breadcrumb: [
        {
          text: 'Gift Cards',
          to: '/gift-cards',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gift-cards/edit/:id',
    name: 'edit-gift-card',
    component: () => import('@/views/gaming/giftCards/control-gift-card/AddEditGiftCard.vue'),
    meta: {
      title: 'Edit Gift Card - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Gift Cards',
      breadcrumb: [
        {
          text: 'Gift Cards',
          to: '/gift-cards',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entertainments',
    name: 'entertainments',
    component: () => import('@/views/gaming/entertainments/Entertainments.vue'),
    meta: {
      title: 'Entertainments - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Entertainments',
      breadcrumb: [
        {
          text: 'Entertainments',
          to: 'entertainments',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entertainments/add',
    name: 'add-entertainments',
    component: () => import('@/views/gaming/entertainments/control-entertainments/AddEditEntertainments.vue'),
    meta: {
      title: 'Add Entertainments- Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Entertainments',
      breadcrumb: [
        {
          text: 'Entertainments',
          to: '/entertainments',

        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entertainments/edit/:id',
    name: 'edit-entertainments',
    component: () => import('@/views/gaming/entertainments/control-entertainments/AddEditEntertainments.vue'),
    meta: {
      title: 'Edit Entertainments - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_category',
      pageTitle: 'Entertainments',
      breadcrumb: [
        {
          text: 'Entertainments',
          to: '/entertainments',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gaming-products',
    name: 'gaming-products',
    component: () => import('@/views/gaming/products/Products.vue'),
    meta: {
      title: 'Gaming Products - Zerocash',
      requiresAuth: true,
      action: 'browes',
      resource: 'gaming_product',
      pageTitle: 'Gaming Products',
      breadcrumb: [
        {
          text: 'Gaming Products',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
